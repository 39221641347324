import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 7rem;
`

type TextProps = {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  centered?: boolean
}

export const Text = styled.p<TextProps>`
  font-size: 1.125rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-align: ${({ centered }) => (centered ? 'center' : 'start')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  line-height: 1.5;
  margin: 0 0 0.75rem;
`
export const OrderedList = styled.ol`
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li<TextProps>`
  display: table;
  counter-increment: item;

  font-size: 1.125rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-align: ${({ centered }) => (centered ? 'center' : 'start')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  line-height: 1.5;
  margin: 0 0 0.75rem;

  &:last-of-type {
    margin: 0;
  }

  &:before {
    content: counters(item, '.') '.';
    display: table-cell;
    padding-right: 0.6em;
  }

  & ol > li {
    margin: 0 0 0.75rem;
  }

  & ol > li:before {
    content: counters(item, '.') '.';
  }

  & ol > li:first-of-type {
    margin: 0.75rem 0 0.75rem;
  }
`
