import * as React from 'react'
import * as S from './EULASectionAtoms'

const EULA: React.FC = () => (
  <S.Container>
    <S.Text bold centered>
      Power Retro by Tappointment – For ATLASSIAN Marketplace
    </S.Text>

    <S.Text bold centered>
      End User License Agreement
    </S.Text>

    <S.Text bold underline>
      Introduction
    </S.Text>

    <S.Text bold>IMPORTANT DISCLAIMER – PLEASE READ AND INTERPRET CAREFULLY:</S.Text>

    <S.Text>
      The present End User License Agreement (hereinafter referred to as EULA) is a legally binding
      agreement between You (either an individual or a legal entity) and Tappointment Ltd.
      [Registered Seat: Hungary, 8000 Székesfehérvár, Király sor 30. EU VAT Number: HU24933069.]
      (hereinafter referred to as Tappointment) for the Power Retro Application for ATLASSIAN
      Marketplace (hereinafter referred to as SOFTWARE).
    </S.Text>

    <S.Text>
      By installing, copying, or otherwise using the SOFTWARE, You (or the legal entity you may
      represent) agree to be bound by the present terms of this EULA.
    </S.Text>

    <S.Text>
      The Tappointment's Privacy Policy, which contains the general data management guidelines of
      the SOFTWARE is available at the following link:{' '}
      <a href="https://www.powerretro.io/privacy-policy">
        https://www.powerretro.io/privacy-policy
      </a>
    </S.Text>

    <S.Text>
      The SOFTWARE is available only at Atlassian Marketplace, therefore, any user who would like to
      download the SOFTWARE must register first at Atlassian Marketplace first by accepting “Terms
      of Use” and “Privacy Policy” of Atlassian Marketplace. Therefore, this EULA is to be read in
      conjunction with atlassian.com “Terms of Use” and “Privacy Policy”.
    </S.Text>

    <S.Text>
      The aforesaid mentioned documents of Atlassian Marketplace are available at the following
      links:
    </S.Text>

    <S.Text>
      Terms of use:{' '}
      <a href="https://www.atlassian.com/licensing/marketplace/termsofuse">
        https://www.atlassian.com/licensing/marketplace/termsofuse
      </a>
    </S.Text>
    <S.Text>
      Privacy Policy:{' '}
      <a href="https://www.atlassian.com/legal/privacy-policy">
        https://www.atlassian.com/legal/privacy-policy
      </a>
    </S.Text>

    <S.Text>
      In case of any conflict between the present EULA and the referred document of Atlassian
      Marketplace, the EULA shall prevail.
    </S.Text>

    <S.Text>
      If You are not accepting the terms of use or privacy policy of Tappointment or Atlassian
      Market (as referred above) please do not download the SOFTWARE or if You downloaded, please
      remove the SOFTWARE and delete or destroy all copies.
    </S.Text>

    <S.OrderedList>
      <S.ListItem bold>
        Grant of License
        <S.OrderedList>
          <S.ListItem>
            The SOFTWARE is protected by copyright laws and international copyright treaties, as
            well as other intellectual property laws and treaties. All title and copyrights in and
            to the SOFTWARE (including but not limited to any images, icons, text files, pdfs or
            other assets contained within the SOFTWARE), the accompanying printed materials, and any
            copies of the SOFTWARE, are owned by Tappointment or its suppliers. This EULA does not
            grant You any rights to use such content. This license grants You the limited,
            worldwide, nonexclusive right to install and use the SOFTWARE (including the related
            on-line documentation) for the number of licensed users, but only if You accept the
            present EULA.
          </S.ListItem>
          <S.ListItem>
            You may not copy or embed elements of the source code of the SOFTWARE into other
            applications, or publish, transmit or communicate the source code to other parties other
            than yourself or the entity You represent.
          </S.ListItem>
          <S.ListItem>
            You are allowed to copy the SOFTWARE for backup, archival as well as testing purposes.
          </S.ListItem>
          <S.ListItem>
            You may not sell, transfer or convey the SOFTWARE to any third party without the prior
            express written consent of Tappointment. If users transfer their rights or grant a
            secondary license without Tappointment's authorization or if the license to use a work
            is transferred without the Tappointment's authorization, the user and the licensee shall
            be jointly liable for performing the use contract.
          </S.ListItem>
          <S.ListItem>
            If You are acting on behalf of a legal entity, You hereby warrant that You are entitled
            to represent the legal entity, and conclude the present EULA on behalf of the legal
            entity.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Ownership and Reservation of Rights
        <S.OrderedList>
          <S.ListItem>
            All rights of any kind, which are not expressly granted by the present EULA, are
            entirely and exclusively reserved to and by Tappointment. As between the parties, all
            title, ownership rights, and intellectual property rights in and to the SOFTWARE and any
            copies, portions, or derivatives thereof, shall remain in Tappointment.
          </S.ListItem>
          <S.ListItem>
            The Software is licensed, not sold. You do not acquire any ownership rights as a result
            of downloading, installing or using the SOFTWARE.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Termination
        <S.OrderedList>
          <S.ListItem>
            You may terminate this EULA at any time by destroying all your copies of the SOFTWARE.
          </S.ListItem>
          <S.ListItem>
            The license will automatically terminate if You fail to comply with the terms of this
            EULA
          </S.ListItem>
          <S.ListItem>
            On termination, You are required to remove the SOFTWARE from your computer and destroy
            all copies of the SOFTWARE and related documentation and all its component parts.
          </S.ListItem>
          <S.ListItem>
            The provisions of the present EULA which by their nature extend beyond the termination
            date of the EULA will survive and remain in effect and enforceable until all obligations
            are fully satisfied.
          </S.ListItem>
          <S.ListItem>
            You understand that Tappointment may modify or discontinue offering the SOFTWARE at any
            time.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Warranties
        <S.OrderedList>
          <S.ListItem>
            The SOFTWARE is provided on an “as is” and “as available” basis without warranty,
            express or implied, of any kind or nature, including, but not limited to, any warranties
            of performance, merchantability, fitness for a particular purpose, or title. You may
            have other statutory rights, but the duration of statutorily required warranties, if
            any, shall be limited to the shortest period permitted by law.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Infringement Indemnification
        <S.OrderedList>
          <S.ListItem>
            Tappointment will hold You harmless, defend and indemnify You, against a third party
            claim to the extent based on an allegation that the SOFTWARE infringes a third party
            intellectual property right, provided that Tappointment: (a) is promptly notified and
            furnished a copy of such Claim, and all other documents that the claim is based on (b)
            is given reasonable assistance in and sole control of the defence thereof and all
            negotiations for its settlement.
          </S.ListItem>
          <S.ListItem>
            If the SOFTWARE becomes, or in the opinion of Tappointment may become, the subject of a
            Claim, Tappointment may, at its option and in its discretion: (a) procure for You the
            right to use the SOFTWARE, free of any liability; (b) replace or modify the SOFTWARE to
            make it non-infringing; or (c) terminate your right to continue using the SOFTWARE and
            refund, in this case, any license fees related to the SOFTWARE paid by You.
          </S.ListItem>
          <S.ListItem>
            You agree that Tappointment and its contractors shall have no liability whatsoever for
            any use, development, modification or maintenance you make of the SOFTWARE other than in
            accordance with this EULA. You shall indemnify and hold harmless Tappointment and 3 its
            contractors from any claims, damages, losses, liabilities, costs, and fees (including
            reasonable attorneys' fees) arising from your use of the Software as well as from your
            failure to comply with any term of this EULA. You must notify Tappointment in written
            form with registered delivery of any such claims, damages, losses, liabilities or of the
            legal proceeding.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Limitation of Liability
        <S.OrderedList>
          <S.ListItem>
            Except for the indemnification clause, neither party will be liable to any person with
            respect to any loss, damage, cost, expense or other claim, for any consequential (such
            as loss of income; loss of business profits or contracts; business interruption; loss of
            the use of money or anticipated savings; loss of information; loss of opportunity,
            goodwill or reputation; loss of, damage to or corruption of data), indirect, special,
            punitive or other damages in relation to the usage of the SOFTWARE.
          </S.ListItem>
          <S.ListItem>
            In no event will the total liability under any claims arising out of this agreement
            exceed the license fees paid under this EULA.
          </S.ListItem>
          <S.ListItem>
            Tappointment is not liable for the integrity of the information or the information
            itself stored at the request of a user.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Governing Law and Jurisdiction
        <S.OrderedList>
          <S.ListItem>
            In the event of any dispute arising from or in connection with the present EULA, so
            especially with its breach, termination, validity or interpretation, the parties exclude
            the state court procedure and agree to submit the matter to the exclusive and final
            decision of the Permanent Arbitration Court attached to the Hungarian Chamber of
            Commerce and Industry (Commercial Arbitration Court Budapest). The Arbitration Court
            proceeds in accordance with its own Rules of Proceedings (supplemented with the
            provisions of the Sub-Rules of Expedited Proceedings). The number of arbitrators shall
            be three and the language to be used in the arbitral proceedings shall be Hungarian. The
            parties exclude the possibility of the retrial of the proceedings as regulated in
            Section IX of Act no. LX of 2017 on Arbitration. In order to settle the legal dispute,
            the Hungarian substantive law shall apply, excluding its private international law
            rules.”
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Support and Equipment
        <S.OrderedList>
          <S.ListItem>
            This EULA does not entitle you to any support, upgrades, patches, or enhancements,
            (hereinafter referred to collectively, as "Support") for the SOFTWARE. Any such Support
            for the SOFTWARE that may be made available by Tappointment, in its sole discretion,
            shall become part of the SOFTWARE and subject to this EULA. You shall be responsible for
            obtaining and maintaining any equipment or ancillary services needed to connect to,
            access, or otherwise use the SOFTWARE, including, without limitation, modems, hardware,
            software, and internet service. You shall be responsible for ensuring that such
            equipment or ancillary services are compatible with the SOFTWARE.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Entire Agreement
        <S.OrderedList>
          <S.ListItem>
            This EULA represents the whole agreement concerning this license between the parties and
            supersedes all prior agreements and representations between them.
          </S.ListItem>
          <S.ListItem>
            Tappointment may amend or modify this EULA from time to time. You and all the user of
            the SOFTWARE fully liable to check the website from time to time to view any such
            changes 4 in the EULA, however You can request to be informed of any changes by
            submitting a written request via email to Tappointment. Please write your request to the
            following email address:{' '}
            <a href="mailto:hello@tappointment.com">hello@tappointment.com</a> All notices and
            enquiries will be answered by Tappointment in a reasonable period of time.
          </S.ListItem>
          <S.ListItem>
            Unless required by Laws, we agree not to make modifications that would substantially
            diminish our obligations during your then-current License Term.
          </S.ListItem>
          <S.ListItem>
            Please note, that use of SOFTWARE is free within the trial period provided automatically
            by Atlassian Marketplace. Please be aware, that after the end of the trial period you
            shall pay the license fee for the use of the SOFTWARE.
          </S.ListItem>
          <S.ListItem>
            It is your sole business decision, whether you subscribe to our SOFTWARE at the end of
            the trial periods and order a monthly or yearly subscription. You may terminate your
            subscription at any time via Atlassian Marketplace. You may find more information on
            termination at Terms of Use of Atlassian Marketplace.{' '}
            <a href="https://www.atlassian.com/licensing/marketplace/">
              https://www.atlassian.com/licensing/marketplace/
            </a>
          </S.ListItem>
          <S.ListItem>
            If the resolution is unacceptable, the customer can request for termination and refund
            for any fees prepaid for use of the affected SOFTWARE for the terminated portion of the
            License Term.
          </S.ListItem>
          <S.ListItem>
            For avoidance of doubt, any Order is subjected to the version of the EULA in effect at
            the time of the Order of the SOFTWARE.
          </S.ListItem>
          <S.ListItem>
            Nothing in these Terms of Use or any Order is intended to, or shall be deemed to, make
            Tappointment your agent, or authorize Tappointment to make or enter into any commitments
            for you or on your behalf.
          </S.ListItem>
          <S.ListItem>
            No waiver of any right under this EULA will be deemed effective unless contained in
            writing signed by a duly authorised representative of the party against whom the waiver
            is to be asserted, and no waiver of any past or present right arising from any breach or
            failure to perform will be deemed to be a waiver of any future rights arising out of
            this EULA.
          </S.ListItem>
          <S.ListItem>
            If any provision of this EULA is held to be unenforceable for any reason, such provision
            shall be reformed only to the extent necessary to make it enforceable and the remaining
            provisions of this EULA shall remain in full force and effect.
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>

      <S.ListItem bold>
        Contact information
        <S.OrderedList>
          <S.ListItem>
            If you have any question or concern regarding this EULA or the SOFTWARE, please write to{' '}
            <a href="mailto:hello@tappointment.com">hello@tappointment.com</a> or give us a call:{' '}
            <a href="tel:+36 1 611 9786">+36 1 611 9786</a>
          </S.ListItem>
        </S.OrderedList>
      </S.ListItem>
    </S.OrderedList>

    <S.Text>Last updated August 23rd, 2022</S.Text>
  </S.Container>
)

export default EULA
