import * as React from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import EULASection from '../components/EULASection'

const EULA: React.FC = () => (
  <Layout>
    <Section color="primary" clip={{ bottomRight: true }}>
      <Navigation />
    </Section>
    <Section color="white">
      <EULASection />
    </Section>
    <Section color="secondary" clip={{ topLeft: true }} glue>
      <Footer />
    </Section>
  </Layout>
)

export default EULA
